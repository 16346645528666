.total_class{
    padding: 0;
    margin:0;
}

.menu_class{
    padding-left: 25%;/*getPadding();*/
    padding-right: 25%;/*getPadding();*/
}

.divSubTitle_class{
    width:100%;
    height:auto;
    display:inline-block;
}

.subTitle_class{
    font-family: 'Text_Bold', serif;
    font-variant: small-caps;
    font-size: 35px;
    letter-spacing: 2px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.line_class{
    margin: 8px auto auto;
}

.info_class{
    list-style:none;
    padding-left:0;
    padding-bottom:0;
    margin:0;
    position: relative;
    float: left;
    width: 45%;
}

.info_text_class{
    display:inline-block;
    margin-right:10px;
    margin-bottom: 0;
    font-size: 23px;
    font-family: Text_Regular, serif;
}

.icon_class{
    width:22px;
    display:inline-block;
    margin-right:10px;
    padding-top:0;
    padding-bottom: 10px;
}

.image_class{
    width:100%;
}

.divImage_class{
    float:right;
    width:55%;
    margin-bottom:50px;
}

.list-group-item{
    padding:0;
}