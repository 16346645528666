body {
    font-family: 'Text_Medium',serif;
    font-size: 20px;
}

.navbar-nav {
    display: block;
}

.line_class{
    margin: 6px auto auto;
    border: 1px solid rgb(89,127,251);
}

.nav_bar{
    font-variant: small-caps;
    color: black;
    font-family: Text_Bold, serif;
    font-size: 18px;
    letter-spacing: 1.5px;
    margin-left:6px;
    float:right;
}

.nav_bar_voltar{
    font-variant: small-caps;
    color:black;
    font-family: Text_Bold, serif;
    font-size: 18px;
    letter-spacing: 1.5px;
    float:left;
}

.divMenu_app{
   /* background-image: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0.6),rgba(255,255,255,0) );*/
    background-color:white;
    border-width:0px;
    margin:0;
    width:100%;
    padding: 0 0 20px;

}

.menu_app{
    width:100%;
    padding-left: 25%;/*getPadding();*/
    padding-right: 25%;/*getPadding();*/
    font-size: 15px;
    margin: 20px 0 0;
    float:right;
}

.navbar-light .navbar-nav .nav-link {
    color: rgb(0,0,0);
}

.navbar-light .navbar-nav .nav-link:hover {
    color: rgb(0,0,0);
}





