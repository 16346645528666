.menu_simulation{
    padding-left: 25%; /*getPadding(),*/
    padding-right: 25%; /*getPadding(),*/
}

.subTitle_simulation{
    font-family: Text_Bold, serif;
    font-variant: small-caps;
    font-size: 35px;
    letter-spacing: 1px;
    margin-top: 50px; /*getSubMenuTop(),*/
    margin-bottom: 20px;
}

.form_simulation{
    box-sizing: border-box;
}

.formLegend_simulation{
    font-family: Text_Bold, serif;
    font-variant: small-caps;
    font-size: 13px;
    letter-spacing: 1px;
}

.ratio_simulation{
    display: inline-block;
    width: 100%;
    margin: 0;
    font-size: 23px;
    padding: 0 0 20px;
}

.simulation_right{
    padding:0;
    float:right;
    width:50%;
}

.simulation_left{
    padding:0;
    float:left;
    width:50%;
}


.dateBet_simulation{
    margin-top: 0;
    width: 10%;
    font-size: 36px;
    display: inline-block;
    text-align:center;
    font-family: Text_Bold, serif;
}

.submitBtn_simulation{
    margin-top: 50px;/*getSubMenuTop(),*/
    margin-bottom: 60px;/*getMenuTop(),*/
    border-radius: 0;
    font-size: 23px;
    box-shadow: none;
}

.infoObri_simulation{
    font-family: Text_Bold, serif;
    font-variant: small-caps;
    font-size: 13px;
    letter-spacing: 1px;
}


.form-control-lg {
    font-size: 36px;
    font-family: 'Display_Bold_Italic', serif;
}
.form-control {
    /* color:rgb(255,65,99);*/
    color:rgb(89,127,251);
    border-radius: 0;
}

input#formBasicText, input#formBasicEmail {
    border: 0;
    outline: 0;
    border-bottom: 3px solid black;
    padding:0;
    line-height: 1px;
    height: 60px;
}
input#formBasicText:focus, input#formBasicEmail:focus{
    border:0;
    outline:0;
    /* color:rgb(255,65,99);*/
    color:rgb(89,127,251);
    box-shadow:none;
    /*border-bottom: 3px solid rgb(255,65,99);*/
    border-bottom: 3px solid rgb(89,127,251);
}

.text-muted {
    color: black !important;
}

input[type=checkbox] {
    margin-top: 10px;
    background: white;
}

.react-datepicker-wrapper {
    width: 45%;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

input[type="text"] {
    border: 0;
    outline: 0;
    border-bottom: 3px solid black;
    padding: 0;
    line-height: 1px;
    height: 60px;
    width: 100%;
    font-size: 36px;
    font-family: "Display_Bold_Italic", serif;
    /*color: rgb(255,65,99);*/
    color:rgb(89,127,251);
}

input[type="text"]:focus {
    /*border-bottom: 3px solid rgb(255,65,99);*/
    border-bottom: 3px solid rgb(89,127,251);

}

textarea.form-control {
    border-radius: 0;
    font-size: 23px;
    outline:0;
    box-shadow:none;
    color: rgb(89,127,251);
    border: 3px solid black;
}

textarea.form-control:focus {
    border-radius: 0;
    font-size: 23px;
    outline:0;
    box-shadow:none;
    color: rgb(89,127,251);
    border: 3px solid rgb(89,127,251);
}

button.btn.btn-none:hover{
    border-radius: 0;
    font-size: 23px;
    outline:0;
    box-shadow:none;
    background: rgb(89,127,251) !important;
    color: black;
    border: 3px solid black;
}

button.btn.btn-none {
    border-radius: 0;
    font-size: 23px;
    outline:0;
    box-shadow:none;
    color: black;
    border: 3px solid black;
}

button.react-button-loaders__btn.sc-EHOje.fuBhhA:hover,button.react-button-loaders__btn.sc-EHOje.fuBhhA {
    border: 3px solid black;
    color: black;
}

.fuBhhA:focus,.fuBhhA {
    box-shadow:none;
    background: rgb(89,127,251) !important;
    color: black;
    font-family: 'Text_Bold',serif;
    text-align: center;
    letter-spacing: 1px;
    font-variant: small-caps;
}

.cxhzGU {
    font-size: 18px !important;
    width: 100%;
}